import React from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import { Helmet } from 'react-helmet'
import Content from 'components/new/content'
import Layout from 'components/layout'
import { graphql } from 'gatsby'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import P from 'components/new/typography/p'
import Spacer from 'components/new/spacer'
import Ul from 'components/new/ul'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import { GatsbyImage } from 'gatsby-plugin-image'
import Button from 'components/button/button'
import Form from 'components/forms/connected-support'
import { useToggle } from '~/hooks/use-toggle'

const RemoveSupportToolsPage = ({ data }) => {
  const { heroImage, displayImage } = data

  const [formModalOpen, toggleFormModal] = useToggle()

  return (
    <Layout>
      <Helmet>
        <title>Remote Support Tools | Hutson Inc</title>
      </Helmet>

      <Hero image={heroImage.childImageSharp.gatsbyImageData} title='Remote Support Tools' />

      <Content kind='full'>
        <H2>Get support in near real time</H2>
        <P>
          With Remote Display Access (RDA), our support team can see exactly what's on your display
          to assist real time. Given your consent, we'll connect to your display and walk you
          through how to troubleshoot an issue, change a setting, or optimize your machine. This
          tools is also great for own internal use. Need to walk a new operator through something?
          You or another experienced operator can use RDA to connect to the display.
        </P>
        <Spacer variable />
        <RelativeContainer>
          <DisplayImg
            image={displayImage.childImageSharp.gatsbyImageData}
            alt='John Deere Gen 4 Display'
          />
          <H3>What you need to enable Remote Display Access</H3>
          <P>
            To enable Remote Display Access, your machine must be factory-equipped or
            field-installed with:
          </P>
          <Ul>
            <li>Gen 5 or Gen 4 display</li>
            <li>4G modem</li>
            <li>Ethernet Cable</li>
          </Ul>
          <P>
            You'll also need an Operations Center account with appropriate partnerships set up with
            Hutson.
          </P>
          <Spacer size='m' variable />
          <ButtonContainer>
            <Button ghost color='green' onClick={toggleFormModal}>
              Request more information
            </Button>
          </ButtonContainer>
        </RelativeContainer>
      </Content>
      <LightBackground>
        <Content kind='full'>
          <H2>Resolve issues more efficiently</H2>
          <P>
            Service Advisor Remote is another tool we use to help monitor your machine in near real
            time to isolate potential issues. If we get an alert for your machine, we can diagnose
            the issue and let you know before something goes wrong. We may know what the issue is
            before even looking at your machine, allowing our technicians to bring the right parts
            and tools needed to get you back up and running. This also enables us to make wireless
            software updates when needed.
          </P>
          <Spacer variable />
          <H3>Other benefits of Service Advisor Remote</H3>
          <Spacer size='m' variable />
          <Grid>
            <Column>
              <ColumnContent>
                <H3>Remote Machine Recording</H3>
                <P>
                  This enables our service department to perform remote diagnostics in real time so
                  they bring the right parts and tools to repair your machine. This also enables
                  wireless software updates on most software when needed.
                </P>
              </ColumnContent>
            </Column>
            <Column>
              <ColumnContent>
                <H3>Remote Programming</H3>
                <P>
                  With Remote Programming, our team can connect to your machine to resolve certain
                  technical problems. We can push updates to your machine to fix issues and change
                  settings.
                </P>
              </ColumnContent>
            </Column>
            <Column>
              <ColumnContent>
                <H3>Managing Diagnostic Trouble Codes</H3>
                <P>
                  Our team can view, clear and refresh your machines Diagnostic Trouble Codes
                  (DTCs), allowing us to diagnose issues and prepare if a service call is required.
                </P>
              </ColumnContent>
            </Column>
          </Grid>
        </Content>
      </LightBackground>

      <Form toggleModal={toggleFormModal} modalOpen={formModalOpen} />
    </Layout>
  )
}

const LightBackground = styled.div`
  background-color: ${props => props.theme.color.n20};
`

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 900px) {
    height: 450px;
  }
`

const Grid = styled.div`
  @media (min-width: 900px) {
    ${clearfix}
  }
`

const Column = styled.div`
  background-color: #fff;

  @media (max-width: 899px) {
    :not(:last-child) {
      margin-bottom: ${props => props.theme.size.l};
    }
  }

  @media (min-width: 900px) {
    ${column('1/3')}
  }
`

const ColumnContent = styled.div`
  padding: ${props => props.theme.size.m};
`

const RelativeContainer = styled.div`
  position: relative;
  ${clearfix}
`

const DisplayImg = styled(GatsbyImage)`
  display: none;

  @media (min-width: 900px) {
    display: inline-block;
    float: right;
    margin: 16px 0 16px 16px;
    max-width: 360px;
    width: 100%;
  }
`

const ButtonContainer = styled.div`
  a {
    display: inline-block;
    text-decoration: none;
  }
`

export const pageQuery = graphql`
  query remoteSupportToolsPageQuery {
    heroImage: file(relativePath: { eq: "connected-support/service-advisor-remote-hero.jpg" }) {
      ...FullWidthImage
    }
    displayImage: file(relativePath: { eq: "connected-support/gen-4-display.jpg" }) {
      ...SharpImage800
    }
  }
`

export default RemoveSupportToolsPage
